import { Button } from "./Button";

export const FlipButton = ({ flipText, label, children, ...rest }) => {
  return (
    <Button {...rest}>
      <span className="inline-flex h-[calc(1em+2px)] flex-col overflow-hidden ">
        <span className="leading-[calc(1em+2px)] transition-transform group-hover/button:translate-y-[calc((1em+2px)*-1)]">
          {label}
        </span>
        <span
          className="leading-[calc(1em+2px)] transition-transform group-hover/button:translate-y-[calc((1em+2px)*-1)]"
          aria-hidden
        >
          {flipText || label}
        </span>
      </span>
      {children}
    </Button>
  );
};
