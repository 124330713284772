import React, { useEffect, useState, useContext, useCallback } from 'react'
import { Button, List, notification, Select, Steps, Spin, Popover } from 'antd'
import { conduktClient } from "clients/condukt"
import { AppContext } from "AppContextProvider";
import {
    LoadingOutlined,
    IdcardOutlined,
    FileSearchOutlined,
} from '@ant-design/icons'


const formatDatetime = (inputStr, timeZone = 'UTC') => {
    const inputDatetime = new Date(inputStr);
    const options = {
        timeZone,
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
        timeZoneName: 'short'
    };
    const formattedDatetime = new Intl.DateTimeFormat('en-US', options).format(inputDatetime);
    return formattedDatetime;
};


const pageSize = 10;

const statusOptions = [
    {
        label: "Created",
        value: "created",
    },
    {
        label: "Processing",
        value: "processing",
    },
    {
        label: "Done",
        value: "done",
    },
    {
        label: "Failed",
        value: "failed"
    }
]

const App = () => {
    const [loading, setLoading] = useState(false)
    const [orders, setOrders] = useState([])
    const [ordersCount, setOrdersCount] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [statusFilter, setStatusFilter] = useState(statusOptions[2]?.value)

    const { selectedOrderId, setSelectedOrderId, setMenuItem, setTabOpen } = useContext(AppContext);

    const getOrders = useCallback(async () => {
        setLoading(true)
        try {
            const offset = (currentPage - 1) * pageSize
            const res = await conduktClient.fetchBusinessProfileOrders(offset, pageSize, statusFilter)
            const orders = res?.results || []
            const ordersCount = res?.num_results || 0
            setOrders(orders)
            setOrdersCount(ordersCount)

        } catch (error) {
            notification.error({ message: "Error during past orders retrieval" })
        }
        setLoading(false)
    }, [currentPage, statusFilter])

    const selectOrder = (order) => {
        setSelectedOrderId({
            bpoOrderId: order?.id,
            uboOrderId: null
    })
        setMenuItem("newRequest")
        setTabOpen("businessProfile")
    }

    useEffect(() => {
        getOrders()
    }, [ordersCount, getOrders])

    useEffect(() => {
        setCurrentPage(1)
    }, [statusFilter])

    const getOrderTitle = (order) => {
        if (order?.status === "done") {
            return `${order?.country} |  ${order?.profile?.identity?.name} | ${order?.registration_number} | ${formatDatetime(order?.created_at)}`
        }
        return `(${order?.status}) | ${order?.country} | ${order?.registration_number} | ${formatDatetime(order?.created_at)}`
    }

    const getActionButton = (order) => {
        if (order?.id === selectedOrderId.bpoOrderId) {
            return <Button size='large' type="primary">Selected</Button>
        }

        return (
            <div style={{
                display: "flex"
            }}>
                <Button
                    style={{ minWidth: "6rem" }}
                    size="large"
                    onClick={() => selectOrder(order)}
                >
                    Select
                </Button>
            </div>

        )
    }

    const getStatus = (orderStatus) => {
        if (orderStatus === "done") {
            return "process"
        }
        if (orderStatus === "failed") {
            return "error"
        }

        return "wait"
    }

    const getIcon = (status, DefaultIcon) => {
        if (['failed', 'done'].includes(status)) {
            return <DefaultIcon />
        }

        return <LoadingOutlined style={{ color: "#45464a" }} spin />
    }


    const getProgressBar = (order) => {
        const processingState = order?.processing_state || {}

        const items = [
            {
                status: getStatus(processingState?.fetch_company_identity?.status),
                icon: getIcon(processingState?.fetch_company_identity?.status, IdcardOutlined)
            },
            {
                status: getStatus(processingState?.download_documents?.status),
                icon: getIcon(processingState?.download_documents?.status, FileSearchOutlined)

            },
        ]

        return (
            <Steps
                size='small'
                // progressDot={customDot}
                items={items}
            />
        )
    }

    return (
        <>
            <div style={{
                display: "flex"
            }}>
                <Select
                    style={{ width: "30%" }}
                    options={statusOptions}
                    defaultValue={statusOptions[2]}
                    onChange={(statusFilter) => setStatusFilter(statusFilter)}
                />
            </div>

            <br />
            <br />
            <List
                className="demo-loadmore-list"
                loading={loading}
                itemLayout="horizontal"
                dataSource={orders}
                renderItem={(order) => (
                    <List.Item
                        actions={[
                            getProgressBar(order),
                            getActionButton(order),
                        ]}
                        className='order-list-item'
                    >
                        <List.Item.Meta
                            title={
                                getOrderTitle(order)
                            }
                            onClick={() => selectOrder(order)}
                        >

                        </List.Item.Meta>
                    </List.Item>
                )}
                pagination={{
                    current: currentPage,
                    pageSize: pageSize,
                    total: ordersCount,
                    onChange: page => setCurrentPage(page),
                    showSizeChanger: false,
                }}
                locale={{
                    emptyText: <p className='condukt-green'>No orders have been found</p>
                }}
            />
        </>

    )
}

export default App
