import React from "react";
import { Table } from "antd";
import { addressToHumanReadable } from "./utils";

const columns = [
  {
    dataIndex: "property",
    key: "property",
  },
  {
    dataIndex: "value",
    key: "value",
  },
];

const OfficerTable = ({ officer }) => {
  const getOfficerData = (officer) => {
    const data = [
      {
        property: "Type",
        value: officer?.type,
      },
      {
        property: "Name",
        value: officer?.name,
      },
      {
        property: "Role",
        value: officer?.role,
      },
      {
        property: "Address",
        value: addressToHumanReadable(officer?.address),
      },
    ];

    if (officer?.type === "NATURAL_PERSON") {
      data.push(
        {
          property: "Birth Date",
          value: officer?.date_of_birth,
        },
        {
          property: "Nationality",
          value: officer?.nationality,
        }
      );
    } else {
      data.push(
        {
          property: "Registration Country",
          value: officer?.registration_country,
        },
        {
          property: "Registration Number",
          value: officer?.registration_number,
        }
      );
    }
    return data;
  };

  return (
    <>
      <Table
        showHeader={false}
        columns={columns}
        dataSource={getOfficerData(officer)}
        pagination={false}
        bordered
      />
      <br />
    </>
  );
};

const Officers = ({ officers }) => {
  if (!officers || !officers?.length) {
    return (
      <div className="centered">
        <p className="condukt-green">No Officers have been identified</p>
      </div>
    );
  }

  return (
    <>
      {officers?.map((officer, i) => {
        return <OfficerTable key={`${officer.name}_${i}`} officer={officer} />;
      })}
    </>
  );
};

export default Officers;
