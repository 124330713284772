import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Checkbox, Menu, Popover } from "antd";
import { AppContext } from "AppContextProvider";

export const HiddenSettings = ({ children }) => {
  // Get the state from provider
  const { debugMode, setDebugMode, kyc, setKyc } = useContext(AppContext);

  const menu = (
    <Menu>
      <Menu.Item key="Debug mode">
        <Checkbox checked={debugMode} onChange={() => setDebugMode(!debugMode)}>
          Debug mode
        </Checkbox>
      </Menu.Item>
      <Menu.Item key="Kyc mode">
        <Checkbox checked={kyc} onChange={() => setKyc(!kyc)}>
          KYC mode
        </Checkbox>
      </Menu.Item>
    </Menu>
  );

  return (
    <Popover placement="bottom" trigger="hover" content={menu}>
      <div
        style={{
          color: "white",
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        {children}
      </div>
    </Popover>
  );
};

HiddenSettings.propTypes = {
  children: PropTypes.node,
};
