import { motion } from "framer-motion";
import { useState } from "react";
import config from "../../config";

const arrowVariants = {
  show: {
    pathLength: 1,
    opacity: 1,
  },
  hide: {
    pathLength: 0,
    opacity: 0,
  },
};

export const ContactUsLink = () => {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <a
      href={`mailto:${config.CONDUKT_CONTACT_EMAIL}`}
      className="flex items-center gap-1.5 font-bold hover:underline hover:text-foreground"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <span>Contact Us</span>
      <svg
        width="9"
        height="8"
        viewBox="0 0 9 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.5 1L8.5 4L5.5 7"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <motion.path
          d="M8.5 4H1"
          stroke="currentColor"
          strokeLinecap="round"
          variants={arrowVariants}
          initial="hide"
          animate={isHovering ? "show" : "hide"}
        />
      </svg>
    </a>
  );
};
