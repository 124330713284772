import { AppContext } from "AppContextProvider";
import { useContext } from "react";

export const PERMISSIONS = Object.freeze({
  ITALY: Symbol("italy"),
  GERMANY: Symbol("germany"),
  UNITED_KINGDOM: Symbol("united_kingdom"),
  FRANCE: Symbol("france"),
  UBO: Symbol("ubo"),
  KYC: Symbol("kyc"),
  HIDDEN_SETTING: Symbol("hidden_setting"),
});

const ROLE_PERMISSIONS = Object.freeze({
  ADMIN: [
    PERMISSIONS.ITALY,
    PERMISSIONS.GERMANY,
    PERMISSIONS.UNITED_KINGDOM,
    PERMISSIONS.FRANCE,
    PERMISSIONS.UBO,
    PERMISSIONS.KYC,
    PERMISSIONS.HIDDEN_SETTING,
  ],
  COMMON: [
    PERMISSIONS.ITALY,
    PERMISSIONS.GERMANY,
    PERMISSIONS.UNITED_KINGDOM,
    PERMISSIONS.FRANCE,
    PERMISSIONS.KYC,
    PERMISSIONS.HIDDEN_SETTING,
  ],
  ALL_COUNTRIES: [
    PERMISSIONS.ITALY,
    PERMISSIONS.GERMANY,
    PERMISSIONS.UNITED_KINGDOM,
    PERMISSIONS.FRANCE,
  ],
  KYC: [PERMISSIONS.KYC],
  UBO: [PERMISSIONS.UBO],
  ITALY: [PERMISSIONS.ITALY],
  GERMANY: [PERMISSIONS.GERMANY],
  UNITED_KINGDOM: [PERMISSIONS.UNITED_KINGDOM],
  FRANCE: [PERMISSIONS.FRANCE],
});

const USER_PERMISSIONS = {
  "c-df81fbee-5b03-482a-9e6b-9c0f0d2e1ecb": [ROLE_PERMISSIONS.ITALY],
  "c-101bc56c-7744-48b1-a4b4-517a88fdf4e6": [ROLE_PERMISSIONS.ADMIN],
};

const useHasPermission = (permission) => {
  const app = useContext(AppContext);
  const customer_id = app?.jwt?.customer_id;
  if (customer_id === undefined) {
    return;
  }
  const userPermissions = USER_PERMISSIONS[customer_id] || [
    ROLE_PERMISSIONS.COMMON,
  ];
  return userPermissions.flat().includes(permission);
};

export default useHasPermission;
