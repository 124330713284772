import React from 'react';

import { Button, InputNumber, Tooltip, notification } from 'antd';
import {
    FastBackwardOutlined, BackwardOutlined, ForwardOutlined,
    FastForwardOutlined, ZoomInOutlined, ZoomOutOutlined, DownloadOutlined, PrinterOutlined
} from '@ant-design/icons';

const ControlPanel = ({
    setScale, pageNumber, numPages, onPageChange, scale, fileUrl, fileName
}) => {

    const onDownload = async () => {
        try {
            // Use fetch API to get the file as a Blob
            const response = await fetch(fileUrl);
            if (!response.ok) {
                throw new Error("Unable to download file")
            }
            const file = await response.blob();

            // Create a URL for the blob
            const url = window.URL.createObjectURL(file);

            // Create a temporary anchor element and set the necessary attributes
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = fileName

            // Append the anchor to the body, trigger click, and remove it
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);

            // Release the URL
            window.URL.revokeObjectURL(url)
        } catch (error) {
            notification.error({ message: "Error downloading file" })
        }
    }


    if (!fileUrl) {
        return <>
        </>
    }

    return (
        <div>
            <div className="control-panel" style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: "center",
                gap: "50px",
                margin: 16,
                padding: 16
            }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Tooltip title="First Page">
                        <Button icon={<FastBackwardOutlined />} onClick={() => onPageChange(1)} />
                    </Tooltip>
                    <Tooltip title="Previous Page">
                        <Button icon={<BackwardOutlined />} onClick={() => onPageChange(pageNumber < 2 ? 1 : pageNumber - 1)} />
                    </Tooltip>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "10px",
                            margin: "0 10px",
                        }}
                    >
                        <p>
                            Page
                        </p>
                        <InputNumber
                            min={1}
                            max={numPages || 1}
                            value={pageNumber}
                            onChange={onPageChange}
                        />
                        <p>
                            of {numPages}
                        </p>
                    </div>
                    <Tooltip title="Next Page">
                        <Button icon={<ForwardOutlined />} onClick={() => onPageChange(pageNumber < numPages ? pageNumber + 1 : numPages)} />
                    </Tooltip>
                    <Tooltip title="Last Page">
                        <Button icon={<FastForwardOutlined />} onClick={() => onPageChange(numPages)} />
                    </Tooltip>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Tooltip title="Zoom Out">
                        <Button icon={<ZoomOutOutlined />} onClick={() => setScale(scale - 0.1)} />
                    </Tooltip>
                    <span>{(scale * 100).toFixed()}%</span>
                    <Tooltip title="Zoom In">
                        <Button icon={<ZoomInOutlined />} onClick={() => setScale(scale + 0.1)} />
                    </Tooltip>
                </div>
                <div>
                    <Tooltip title="Download">
                        <DownloadOutlined onClick={onDownload} style={{ fontSize: '16px' }} />
                    </Tooltip>
                </div>
            </div>
        </div>

    )
}

export default ControlPanel;
