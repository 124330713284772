import useVisibility from "./useVisibility";
import { useEffect, useState, useMemo } from "react";
import { notification } from "antd";

const DEFAULT_INTERVAL_TIMEOUT = 3000;
const EXTENDED_INTERVAL_TIMEOUT = 10000;

const DELAY_THRESHOLD_MIN = 2; // 2 minutes; if the order is younger than this, we fetch it more often
const DIE_THRESHOLD_MIN = 30; // 30 minutes; if the order is older than this, we stop trying to (re-)fetch it

export const useOrderRefetcher = (getOrder, selectedOrderId, type) => {
  const [order, setOrder] = useState(null);
  const [orderGetInterval, setOrderGetInterval] = useState(null);
  const isDocumentVisible = useVisibility();

  const isOrderFinished = useMemo(() => {
    if (!order) return false;
    return ["done", "failed"].includes(
      type === "business-profile"
        ? order?.processing_state?.fetch_company_identity?.status
        : order?.processing_state?.download_documents?.status
    );
  }, [order, type]);

  useEffect(() => {
    const setNewInterval = () => {
      const orderCreationDate = order?.created_at
        ? new Date(`${order?.created_at}Z`)
        : new Date();
      const currentDate = new Date();

      const orderAge = currentDate - orderCreationDate;
      const orderAgeMinutes = orderAge / 1000 / 60;
      if (orderGetInterval) clearInterval(orderGetInterval);

      if (orderAgeMinutes > DIE_THRESHOLD_MIN) {
        notification.error({
          message: "Order retrieval failed. Please, try again later.",
        });
        return null;
      }

      if (orderAgeMinutes > DELAY_THRESHOLD_MIN) {
        const intervalId = setInterval(() => {
          getOrder(selectedOrderId.bpoOrderId);
        }, EXTENDED_INTERVAL_TIMEOUT);
        setOrderGetInterval(intervalId);
        return intervalId;
      }

      const intervalId = setInterval(() => {
        getOrder(selectedOrderId.bpoOrderId);
      }, DEFAULT_INTERVAL_TIMEOUT);
      setOrderGetInterval(intervalId);

      return intervalId;
    };

    if (!selectedOrderId.bpoOrderId) {
      return;
    }
    if (!order?.created_at) {
      getOrder(selectedOrderId.bpoOrderId);
      return;
    }

    if (orderGetInterval) {
      clearInterval(orderGetInterval);
      setOrderGetInterval(null);
    }

    if (!isDocumentVisible) {
      if (orderGetInterval) {
        clearInterval(orderGetInterval);
        setOrderGetInterval(null);
      }
      return;
    }

    if (isOrderFinished) return;

    getOrder(selectedOrderId.bpoOrderId); // refetch immediately if the document becomes visible
    const intervalId = setNewInterval();

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [
    isDocumentVisible,
    selectedOrderId.bpoOrderId,
    order?.created_at,
    isOrderFinished,
  ]);

  return { order, setOrder, isOrderFinished };
};

export default useOrderRefetcher;
