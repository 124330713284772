import React, { useContext, useState, useCallback, useMemo } from "react";
import { Row, Col, Tag, Table, notification, Button, Spin } from "antd";
import { conduktClient } from "clients/condukt";
import { AppContext } from "AppContextProvider";
import PDFReader from "./PDFReader";
import useOrderRefetcher from "../../hooks/useOrderRefetcher";

const sortDocuments = (documents) => {
  const order = [
    "INCORPORATION",
    "ARTICLES",
    "UBO_STATEMENT",
    "REGISTER_EXTRACT",
    "OTHER",
  ];

  return documents.sort((a, b) => {
    const tag1 = a?.tags?.[0]?.toUpperCase();
    const tag2 = b?.tags?.[0]?.toUpperCase();

    let indexA = order.findIndex((e) => e === tag1);
    let indexB = order.findIndex((e) => e === tag2);

    if (indexA === -1) indexA = order.length;
    if (indexB === -1) indexB = order.length;

    return indexA - indexB;
  });
};

const removeDateFromDocuments = (documents) => {
  return documents.map((document) => {
    if (document?.name === "Not found" || document?.name === "Pending") {
      return {
        ...document,
        date: "",
      };
    }
    return document;
  });
};

const Documents = () => {
  const [isFetchingFirstTime, setIsFetchingFirstTime] = useState(true);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const { selectedOrderId } = useContext(AppContext);

  const getOrder = useCallback(async (bpoOrderId) => {
    if (isFetchingFirstTime) {
      setIsFetchingFirstTime(false);
    }
    try {
      const order = await conduktClient.fetchBusinessProfileOrder(bpoOrderId);
      setOrder(order);
    } catch (error) {
      notification.error({ message: "Error occurred during order retrieval" });
    }
  }, []);

  const { order, setOrder, isOrderFinished } = useOrderRefetcher(
    getOrder,
    selectedOrderId,
    "documents"
  );

  // Dynamically adjust the interval based on the order creation time
  // Prevent the order from being queried too often

  const documents = useMemo(() => {
    const sorted_documents = sortDocuments(order?.profile?.documents || []);
    const documents = removeDateFromDocuments(sorted_documents);
    return documents;
  }, [order]);

  const columns = [
    {
      title: "Type",
      dataIndex: "tags",
      key: "tags",
      render: (tags) => (
        <>
          {tags.map((tag) => {
            return (
              <Tag
                style={{ color: "#ceff00", borderColor: "#ceff00" }}
                key={tag}
              >
                {tag.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "",
      dataIndex: "id",
      render: (value, document) => {
        if (document?.name === "Not found") {
          return <></>;
        }

        if (document?.name === "Pending" || !isOrderFinished) {
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Spin />
            </div>
          );
        }
        return (
          <>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                style={{ minWidth: "6rem" }}
                type={selectedDocument?.value === value ? "primary" : "default"}
                onClick={() => {
                  if (selectedDocument?.value === value) {
                    setSelectedDocument(null);
                    return;
                  }

                  setSelectedDocument({
                    label: document?.name,
                    value,
                  });
                }}
              >
                {selectedDocument?.value === value ? "Selected" : "Select"}
              </Button>
            </div>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Row gutter={12}>
        <Col span={24}>
          <Table
            id="documents-table"
            loading={isFetchingFirstTime || documents.length === 0}
            columns={columns}
            dataSource={documents}
            pagination={false}
            locale={{
              emptyText: <p></p>,
            }}
          />
          <br />
          {!!order?.profile?.documents?.length && (
            <>
              <PDFReader
                documentId={selectedDocument?.value}
                fileName={selectedDocument?.label}
              />
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

export default Documents;
