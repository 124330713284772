import React, { useState, useCallback, useEffect } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import { conduktClient } from 'clients/condukt'
import ControlPanel from './ControlPanel';
import { Skeleton, notification, Button } from "antd"

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ALLOWED_FILE_TYPES = ["pdf"];

export const show_download_button = (fileName) => {
        if (fileName == "")
        {
            // Raise exception
            throw new Error("fileName is empty")
        }
        if (!!fileName && !ALLOWED_FILE_TYPES.includes(fileName.split('.').pop().toLowerCase())) {
            return true
        }
        return false
    }

const PDFViewer = ({ documentId, fileName }) => {
    const [scale, setScale] = useState(1.0);
    const [numPages, setNumPages] = useState(null);
    const [error, setError] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [pdfUrl, setPdfUrl] = useState(null);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages)
        setIsLoading(false)
    }

    const onDocumentLoadError = () => {
        setIsLoading(false)
        setPdfUrl(null)
        setError("Document download failed")
    }

    const fetchPDF = useCallback(async () => {
        setPdfUrl(null)
        setError(null)
        setPageNumber(1)
        try {
            setIsLoading(true)
            const pdfUrl = await conduktClient.fetchDocument(documentId)
            setPdfUrl(pdfUrl?.download_url)
        } catch (error) {
            notification.error({ message: "Unable to download PDF" })
        }
    }, [documentId])

    useEffect(() => {
        if (!documentId) {
            setPdfUrl(null)
            setError(null)
            return
        }

        fetchPDF();
    }, [documentId, fetchPDF]);

    if (error) {
        return (
            <div style={{ display: "flex", justifyContent: "center" }}>
                <p className='condukt-green'>{error}</p>
            </div>
        )
    }

    if (!pdfUrl || !fileName) {
        return <></>
    }

    if (show_download_button(fileName)) {
        return (
            <div style={{display: "flex", justifyContent: "center"}}>
                {/*  Show download button  */}
                <Button type="primary" onClick={() => window.open(pdfUrl, "_blank")}>Download File(s)</Button>
            </div>
        )
    }


    return (
        <div style={{
            display: "flex",
            justifyContent: "center"
        }}>
            {isLoading && <Skeleton paragraph={{ rows: 10 }} active />}
            <section>
                {
                    !isLoading &&
                    <ControlPanel
                        scale={scale}
                        setScale={setScale}
                        numPages={numPages}
                        pageNumber={pageNumber}
                        onPageChange={(page) => setPageNumber(page)}
                        fileUrl={pdfUrl}
                        fileName={fileName}
                    />
                }
                <Document
                    file={pdfUrl}
                    loading={<Skeleton active />}
                    onLoadSuccess={onDocumentLoadSuccess}
                    onLoadError={onDocumentLoadError}
                >
                    <Page pageNumber={pageNumber} scale={scale} />
                </Document>
            </section>
        </div>
    );
};

export default PDFViewer;
