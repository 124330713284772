import config from "config";

const decodeJWT = (token) => {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export class ConduktAPIClient {
  constructor(baseUrl = config.CONDUKT_API_URL) {
    this.baseUrl = baseUrl;
    this.sessionToken = null;
    this.refreshToken = localStorage.getItem("refreshToken");
    this.jwtTokenDecoded = null;

    if (this.refreshToken) {
      this.getSessionToken();
    }
  }

  async logIn(username, password) {
    const url = `${this.baseUrl}/portal/login/`;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: username,
        password: password,
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return await response.json();
  }

  async logOut() {
    const url = `${this.baseUrl}/portal/logout`;

    const response = await fetch(url, {
      method: "POST",
      headers: { Authorization: `Bearer ${this.refreshToken}` },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return response;
  }

  async getSessionToken() {
    const url = `${this.baseUrl}/v1/refresh-access-token`;
    const response = await fetch(url, {
      method: "POST",
      headers: { Authorization: `Bearer ${this.refreshToken}` },
    });
    if (!response.ok) {
      if (response.status === 401) {
        localStorage.removeItem("refreshToken");
        return window.location.reload();
      }
      throw new Error("Authentication failed");
    }
    const data = await response.json();
    this.sessionToken = data.session_token;
  }

  async getJWTToken() {
    if (this.sessionToken === null) {
      await this.getSessionToken();
    }
    this.jwtTokenDecoded = decodeJWT(this.sessionToken);
    return this.jwtTokenDecoded;
  }

  async getKYCToken() {
    const url = `${this.baseUrl}/internal/v1/fourthline/kyc`;
    return this.makeRequest("GET", url);
  }

  async makeRequest(method, url, options = {}) {
    const headers = {
      Authorization: `Bearer ${this.sessionToken}`,
      ...options.headers,
    };

    const response = await fetch(url, { method, headers, ...options });
    if (response.status === 401) {
      const data = await response.text();
      if (data.includes("Token expired")) {
        await this.getSessionToken();
        return this.makeRequest(method, url, options);
      }
    }
    if (!response.ok) throw new Error("Request failed");
    return response.json();
  }

  async makeFileRequest(method, url, options = {}) {
    const headers = {
      Authorization: `Bearer ${this.sessionToken}`,
      ...options.headers,
    };

    const response = await fetch(url, { method, headers, ...options });
    if (response.status === 401) {
      const data = await response.text();
      if (data.includes("Token expired")) {
        await this.getSessionToken();
        return this.makeFileRequest(method, url, options);
      }
    }
    if (!response.ok) throw new Error("Request failed");
    return response.blob();
  }

  async createBusinessProfileOrder(orderData) {
    const url = `${this.baseUrl}/v1/business-profile-orders`;
    return this.makeRequest("POST", url, {
      body: JSON.stringify(orderData),
      headers: {
        Authorization: `Bearer ${this.sessionToken}`,
        "Content-Type": "application/json",
      },
    });
  }

  async createUBOOrder(orderData) {
    const url = `${this.baseUrl}/v1/orders/ubo-detect-orders`;
    return this.makeRequest("POST", url, {
      body: JSON.stringify(orderData),
      headers: {
        Authorization: `Bearer ${this.sessionToken}`,
        "Content-Type": "application/json",
      },
    });
  }

  async fetchUBOOrder(id) {
    const url = `${this.baseUrl}/v1/orders/ubo-detect-orders/${id}`;
    return this.makeRequest("GET", url);
  }

  async search(country, query, offset = 0, limit = 40) {
    const url = `${this.baseUrl}/v1/search?country=${country}&query=${query}&offset=${offset}&limit=${limit}`;
    return this.makeRequest("GET", url);
  }

  async fetchBusinessProfileOrder(id) {
    const url = `${this.baseUrl}/v1/business-profile-orders/${id}`;
    return this.makeRequest("GET", url);
  }

  async fetchBusinessProfileOrders(offset = 0, limit = 100, status = null) {
    const statusParam = status ? `&status=${status}` : "";
    const url = `${this.baseUrl}/v1/business-profile-orders?offset=${offset}&limit=${limit}${statusParam}`;
    return this.makeRequest("GET", url);
  }

  async fetchBusinessProfile(id) {
    const url = `${this.baseUrl}/v1/business-profiles/${id}`;
    return this.makeRequest("GET", url);
  }

  async fetchDocument(id) {
    const url = `${this.baseUrl}/v1/documents/${id}`;
    return this.makeRequest("GET", url);
  }
}

const conduktClient = new ConduktAPIClient();

export { conduktClient };
