import React, { createContext, useState } from "react";

export const AppContext = createContext(null);

const AppContextProvider = ({ children }) => {
  const [menuItem, setMenuItem] = useState("newRequest");
  const [tabOpen, setTabOpen] = useState("request");
  const [selectedOrderId, setSelectedOrderId] = useState({
    bpoOrderId: null,
    uboOrderId: null,
  });
  const [debugMode, setDebugMode] = useState(false);
  const [kyc, setKyc] = useState(false);
  const [jwt, setJwt] = useState(null);

  return (
    <AppContext.Provider
      value={{
        selectedOrderId,
        setSelectedOrderId,
        menuItem,
        setMenuItem,
        tabOpen,
        setTabOpen,
        debugMode,
        setDebugMode,
        kyc,
        setKyc,
        jwt,
        setJwt,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
