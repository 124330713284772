import { cn } from "cn";
import { forwardRef, useState } from "react";

export const Input = forwardRef(
  (
    {
      className,
      containerClassName,
      type,
      label,
      id,
      children,
      onChange,
      ...props
    },
    ref
  ) => {
    const [value, setValue] = useState("");

    return (
      <div
        className={cn(
          "flex items-center rounded-lg border border-background-dark bg-white transition-colors hover:border-grey focus-within:!border-foreground relative group/input has-[input[aria-invalid=true]]:!border-error",
          containerClassName
        )}
      >
        <label
          className={cn(
            "pointer-events-none absolute left-3 top-1/2 origin-top-left -translate-y-1/2 bg-transparent text-sm !leading-6 text-grey transition-all group-focus-within/input:top-0 group-focus-within/input:bg-background group-focus-within/input:px-0.5 group-focus-within/input:text-[0.625rem] group-focus-within/input:!leading-none group-focus-within/input:text-foreground group-has-[input[aria-invalid=true]]/input:!text-error",
            {
              "top-0 bg-background px-0.5 text-[0.625rem] !leading-none text-foreground":
                !!value,
            }
          )}
          htmlFor={id}
        >
          {label}
        </label>
        <input
          type={type}
          className={cn(
            "h-10 w-full rounded-lg px-3 py-2 text-sm !leading-6 focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50",
            className
          )}
          ref={ref}
          id={id}
          onChange={(e) => {
            if (onChange) onChange(e);
            setValue(e.target.value);
          }}
          {...props}
        />
        {children}
      </div>
    );
  }
);
Input.displayName = "Input";
