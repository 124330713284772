import {conduktClient} from "../../clients/condukt";
import {notification, Skeleton, Table, Typography} from "antd";
import {useCallback, useContext, useEffect, useState} from "react";
import { AppContext } from "AppContextProvider";
import {Modal, Button, theme} from 'antd';
import {CheckCircleOutlined, CloseOutlined} from '@ant-design/icons';
import { Tooltip } from 'antd';

const KycModal = ({person_or_entity, isPerson = false}) => {
    const {useToken} = theme;
    const { token } = useToken();
        const [verified, setVerified] = useState("NOT_STARTED");
        const [modalVisible, setModalVisible] = useState(false);
        const [isLoading, setIsLoading] = useState(false);
        const [kycToken, setKycToken] = useState(null);

    useEffect(() => {
        const KYC = async () => {

            const token = await conduktClient.getKYCToken();
            console.log(token);
            setKycToken(token.validationCode);
        }
        KYC();
    }, [setKycToken,setIsLoading]);




        const handleVerify = () => {
            setIsLoading(true);
            // ACTUAL INTERGARTION HERE
            setTimeout(() => {
                setVerified("SUCCESS");
                setIsLoading(false);
                notification.success({message: 'Verification successful'});
            }, 2000);
        };

        const handleFailVerify = ()=>{
            setIsLoading(true);
            setTimeout(() => {
                setVerified("FAIL");
                setIsLoading(false);
                notification.error({message: 'Verification Failed', description: "The KYC failed because of: XYZ"});
            }, 2000);
        }

        const handleOpenModal = () => {
            setModalVisible(true);
        };

        const handleCloseModal = () => {
            setModalVisible(false);
        };

        const status = () => {
            if (verified === "SUCCESS") {
                return <div style={{ display:'flex', flexDirection:'row'}}><CheckCircleOutlined style={{color: token.colorPrimary}}/><Typography> Verified</Typography></div>
            } else if (verified === "FAIL") {
                return (
            <Tooltip title="Verification failed due to XYZ reason">
                <div  style={{ display:'flex', flexDirection:'row'}}><CloseOutlined style={{color: token.red}}/><Typography> Verification Failed, please proceed with manual checks</Typography></div>
            </Tooltip>
        )
            } else {
                return <Button loading={isLoading} onClick={handleOpenModal}>
                    Verify
                </Button>
            }

        }
        if (kycToken === null) {
            return <Skeleton paragraph={{rows: 1}} active/>
        }

    return (

        <>
            {status()}
            <Modal
                title={"Verification for " + person_or_entity}
                open={modalVisible}
                onOk={handleCloseModal}
                onCancel={handleCloseModal}
            >
                <div className="container">
                    <fl-flow-onboarding token={kycToken}></fl-flow-onboarding>
                </div>

            </Modal>
        </>
    );
    }
;


const KycComponent = () => {

    const [order, setOrder] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const {selectedOrderId} = useContext(AppContext)
    const [orderGetInterval, setOrderGetInterval] = useState(null)


    const getOrder = useCallback(async (selectedOrderId) => {
        setIsLoading(true)
        try {
            const order = await conduktClient.fetchBusinessProfileOrder(selectedOrderId)
            setOrder(order)
        } catch (error) {
            notification.error({message: "Error occurred during order retrieval"})
        }
        setIsLoading(false)
    }, []);

    useEffect(() => {
        if (!selectedOrderId.bpoOrderId) {
            return
        }
        getOrder(selectedOrderId.bpoOrderId)
        const intervalId = setInterval(() => {
            getOrder(selectedOrderId.bpoOrderId);
        }, 3000);

        setOrderGetInterval(intervalId);
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [selectedOrderId.bpoOrderId, getOrder])


    if (isLoading) {
        <>
            <h3>Fetching the order...</h3>
            <Skeleton paragraph={{rows: 20}} active/>
        </>
    }

    clearInterval(orderGetInterval)


    // Check if there are any bos
    const bos = order?.profile?.identity?.beneficial_owners
    const officers = order?.profile?.identity?.officers

    const naturalPersonsBeneficialOwners = bos ? bos.filter(bo => bo.type === "NATURAL_PERSON") : [];
    const naturalPersonsOfficers = officers ? officers.filter(bo => bo.type === "NATURAL_PERSON") : [];

    const legalEntitiesBeneficialOwners = bos ? bos.filter(bo => bo.type === "LEGAL_ENTITY") : [];
    const legalEntitiesOfficers = officers ? officers.filter(bo => bo.type === "LEGAL_ENTITY") : [];


    let content = null

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Verification',
            dataIndex: 'verification',
            key: 'verification',
            render: (text, record) => (
                <KycModal person_or_entity={record.name} isPerson={record.isPerson}/>
            )
        },
    ];



    // Base case there are no BOs and no officers
    if (!naturalPersonsBeneficialOwners && !naturalPersonsOfficers) {

        if (legalEntitiesBeneficialOwners) {
            const data = legalEntitiesBeneficialOwners.map((entity, index) => ({
                    key: index,
                    name: entity.name,
                    isPerson: false
            }));


            content = (
                <>

                    <Typography>No Beneficial Owner or Officer have been identified, please proceed to manual EDD verification.</Typography>

                    <Table columns={columns} dataSource={data} />
                </>
            )
        } else if (legalEntitiesOfficers) {

            const data = legalEntitiesOfficers.map((entity, index) => ({
                key: index,
                name: entity.name,
                isPerson: false
            }));


            content = (
                <>
                    <Typography>No natural person Beneficial Owner or Officer have been identified, please request manually the legal entity to proceed with verification.</Typography>

                    <Table columns={columns} dataSource={data} />
                </>
            )
        } else {
            content = "No natural person Beneficial Owner or Officer have been identified, please request manually the legal entity to proceed with verification."
        }

    }

    // Case where there are BOs
    else if (naturalPersonsBeneficialOwners.length > 0) {
        const data = naturalPersonsBeneficialOwners.map((person, index) => ({
            key: index,
            name: person.name,
            isPerson: true
        }));

        content = (

                <Table columns={columns} dataSource={data} />

        )
    }

    // Case where there are officers
    else if (naturalPersonsOfficers.length > 0) {
        const data = naturalPersonsOfficers.map((person, index) => ({
            key: index,
            name: person.name,
            isPerson: true
        }));

        content = (

                    <Table columns={columns} dataSource={data} />


        )
    }


    return (
        <div>

            <h1>KYC</h1>
            {content}
        </div>
    )


}

export default KycComponent
