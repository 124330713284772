import React, { useState } from 'react';

const Dropdown = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="dropdown">
      <p onClick={toggleDropdown} className="dropdown-button">
        {title} <span className="arrow">{isOpen ? '▲' : '▼'}</span>
      </p>
      {isOpen && <div style={{display: 'flex', padding:10, backgroundColor: 'rgba(38,39,48,1)', gap: 5}}>{children}</div>}
    </div>
  );
};

export default Dropdown;
