import { forwardRef, useState } from "react";
import { Input } from "./Input";
import { cn } from "cn";

/**
 * Accepts all normal HTML Input props except `type`
 */
export const PasswordInput = forwardRef(
  ({ containerClassName, children, ...props }, ref) => {
    const [showPassword, setShowPassword] = useState(false);

    return (
      <Input
        ref={ref}
        type={showPassword ? "text" : "password"}
        containerClassName={cn("pr-4 gap-3", containerClassName)}
        {...props}
      >
        {children}
        <button
          type="button"
          onClick={() => setShowPassword((prev) => !prev)}
          className={cn("text-base text-grey transition-colors", {
            "text-foreground": showPassword,
          })}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 16 16"
            className="size-4"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.2"
              d="M1.33325 7.99967s2-4.66666 6.66667-4.66666c4.66668 0 6.66668 4.66666 6.66668 4.66666s-2 4.66663-6.66668 4.66663c-4.66667 0-6.66667-4.66663-6.66667-4.66663Z"
            />
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.2"
              d="M8 10c1.10457 0 2-.89543 2-2s-.89543-2-2-2-2 .89543-2 2 .89543 2 2 2Z"
            />
          </svg>
        </button>
      </Input>
    );
  }
);

PasswordInput.displayName = "PasswordInput";
