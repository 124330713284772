import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Theme from "./Theme";
import reportWebVitals from "./reportWebVitals";
import Auth from "modules/auth";
import App from "modules/App";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Hotjar from "@hotjar/browser";
import config from "./config";
import AppContextProvider from "AppContextProvider";

let router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Theme>
        <AppContextProvider>
          <Auth />
        </AppContextProvider>
      </Theme>
    ),
  },
]);

if (localStorage.getItem("refreshToken")) {
  router = createBrowserRouter([
    {
      path: "/",
      element: (
        <Theme>
          <AppContextProvider>
            <App />
          </AppContextProvider>
        </Theme>
      ),
    },
  ]);
}

const root = ReactDOM.createRoot(document.getElementById("root"));

Hotjar.init(config.HOTJAR_SITE_ID, 6);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
