import React from 'react'
import { Layout, ConfigProvider } from 'antd'

const conduktTheme = {
  token: {
    colorPrimary: '#ceff00',
    colorBgBase: "#262730",
    colorTextBase: "#e6e6e7",
    colorTextLightSolid: "#0e1117",

    colorBgLayout: "#0e1117",
    colorBgContainer: '#262730',

    colorTextPlaceholder: "#7f8084",
    borderRadius: 2,
  },
  components: {
    Layout: {
      siderBg: "#262730",
      triggerBg: "#262730",
    },
    Select: {
      optionSelectedBg: "#ceff00",
      optionSelectedColor: "#0f1117",
    },
  }
}


const Theme = ({ children }) => {
  return (
    <ConfigProvider
      theme={conduktTheme}
    >
      <Layout
        style={{
          minHeight: '100vh',
        }}
      >
        {children}
      </Layout>
    </ConfigProvider>
  )
}
export default Theme
