import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva } from "class-variance-authority";
import { cn } from "cn";

const buttonVariants = cva(
  "group/button inline-flex h-10 items-center justify-center whitespace-nowrap rounded-lg px-4 py-2 text-sm font-medium !leading-6 transition-colors duration-300 disabled:pointer-events-none disabled:border-foreground disabled:border-opacity-[0.16] disabled:bg-transparent disabled:text-foreground disabled:text-opacity-[0.32]",
  {
    variants: {
      variant: {
        primary: "border border-transparent bg-foreground text-primary",
        outline:
          "border border-foreground border-opacity-[0.16] bg-transparent hover:bg-background-dark",
        ghost: "bg-transparent text-foreground hover:bg-background-dark",
      },
    },
    defaultVariants: {
      variant: "primary",
    },
  }
);

const Button = React.forwardRef(
  ({ className, variant, asChild = false, ...props }, ref) => {
    const Component = asChild ? Slot : "button";
    return (
      <Component
        className={cn(buttonVariants({ variant, className }))}
        ref={ref}
        {...props}
      />
    );
  }
);
Button.displayName = "Button";

export { Button, buttonVariants };
