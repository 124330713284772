import React, { useContext, useState, useCallback } from "react";
import { Row, Col, Skeleton } from "antd";
import { AppContext } from "AppContextProvider";
import { conduktClient } from "clients/condukt";
import Company from "./Company";
import BeneficialOwners from "./BeneficialOwners";
import Officers from "./Officers";
import { notification } from "antd";
import useOrderRefetcher from "../../hooks/useOrderRefetcher";

const LoadingState = ({ message }) => {
  return (
    <>
      <h3>{message}</h3>
      <Skeleton paragraph={{ rows: 20 }} active />
    </>
  );
};

const BusinessProfile = () => {
  const [isLoading, setIsLoading] = useState(false);
  const getOrder = useCallback(async (selectedOrderId) => {
    setIsLoading(true);
    try {
      const order =
        await conduktClient.fetchBusinessProfileOrder(selectedOrderId);
      setOrder(order);
    } catch (error) {
      notification.error({ message: "Error occurred during order retrieval" });
    }
    setIsLoading(false);
  }, []);

  const { selectedOrderId, debugMode } = useContext(AppContext);
  const { order, setOrder, isOrderFinished } = useOrderRefetcher(
    getOrder,
    selectedOrderId,
    "business-profile"
  );

  if (isLoading) return <LoadingState message={"Fetching the order..."} />;

  if (!isOrderFinished)
    return <LoadingState message={"Processing the order..."} />;

  if (order?.processing_state?.fetch_company_identity?.status === "failed") {
    return (
      <div className="centered">
        <p className="condukt-green">Order processing failed</p>
        {debugMode && (
          <>
            <h1>Debug Dump</h1>
            <textarea
              readOnly
              value={JSON.stringify(order, null, 2)}
              style={{ width: "100%", height: "800px" }}
            />
          </>
        )}
      </div>
    );
  }

  const identity = order?.profile?.identity || {};
  const beneficialOwners = order?.profile?.identity?.beneficial_owners || [];
  const officers = order?.profile?.identity?.officers || [];

  return (
    <>
      <Row gutter={12}>
        <Col span={8}>
          <div className="centered">
            <h2>Company</h2>
          </div>
          <Company identity={identity} />
        </Col>
        <Col span={8}>
          <div className="centered">
            <h2>Beneficial Owners</h2>
          </div>

          <BeneficialOwners beneficialOwners={beneficialOwners} />
        </Col>
        <Col span={8}>
          <div className="centered">
            <h2>Officers</h2>
          </div>
          <Officers officers={officers} />
        </Col>
      </Row>
      {debugMode && (
        <>
          <h1>Debug Dump</h1>
          <textarea
            readOnly
            value={JSON.stringify(order, null, 2)}
            style={{ width: "100%", height: "800px" }}
          />
        </>
      )}
    </>
  );
};

export default BusinessProfile;
