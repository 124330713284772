import React, { useContext, useEffect, useState } from "react";
import {
  SearchOutlined,
  IdcardOutlined,
  FileSearchOutlined,
  ClusterOutlined,
} from "@ant-design/icons";
import { Tabs } from "antd";
import Request from "./Request";
import BusinessProfile from "../business-profile";
import Documents from "../documents";
import { AppContext } from "AppContextProvider";
import KycComponent from "../kyc";
import Ubo from "../ubo";
import useHasPermission, { PERMISSIONS } from "../../clients/useHasPermission";

const App = () => {
  const [tabs, setTabs] = useState([]);
  const { tabOpen, setTabOpen, selectedOrderId, kyc } = useContext(AppContext);
  const UBOPermission = useHasPermission(PERMISSIONS.UBO);
  const KYCPermission = useHasPermission(PERMISSIONS.KYC);

  useEffect(() => {
    const tabs = [
      {
        key: "request",
        text: "Request",
        Icon: SearchOutlined,
        Component: Request,
      },
      {
        key: "businessProfile",
        text: "Business Profile",
        Icon: IdcardOutlined,
        Component: BusinessProfile,
        disabled: !selectedOrderId.bpoOrderId,
      },
      {
        key: "documents",
        text: "Documents",
        Icon: FileSearchOutlined,
        Component: Documents,
        disabled: !selectedOrderId.bpoOrderId,
      },
    ];
    if (UBOPermission) {
      tabs.push({
        key: "ubo",
        text: "UBO",
        Icon: ClusterOutlined,
        Component: Ubo,
        disabled: !selectedOrderId.uboOrderId,
      });
    }

    if (kyc && KYCPermission) {
      tabs.push({
        key: "kyc",
        text: "KYC",
        Icon: FileSearchOutlined,
        Component: KycComponent,
        disabled: !selectedOrderId.bpoOrderId,
      });
    }

    setTabs(tabs);
  }, [selectedOrderId, kyc, UBOPermission, KYCPermission]);

  return (
    <Tabs
      activeKey={tabOpen}
      onChange={(tabOpen) => setTabOpen(tabOpen)}
      items={tabs.map(({ key, text, Icon, Component, disabled }, i) => {
        return {
          key,
          label: text,
          children: <Component id={key} />,
          icon: <Icon />,
          disabled,
        };
      })}
      destroyInactiveTabPane={true}
    />
  );
};
export default App;
